.client-filters {
  .quick-filter-container {
    display: flex;
    gap: 24px;
  }

  .active-filter {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    .edit-btn {
      .button-icon {
        color: $link-color;
      }
    }
    .close-btn {
      .button-icon {
        color: $danger-color;
      }
    }
    .filter-values {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-size: 14px;
    }
    .filter-value {
      color: $nav-text-color;
      line-height: 1.5em;
      &.statuses-value {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }
    }
    .filter-name {
      font-weight: bold;
      color: $title-text-color;
    }
    .statuses-list {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .status-item {
        @include status_general_style;
        padding: 10px 20px;
      }
    }
    .filter-clients-found {
      padding: 6px 12px;
      border-radius: 4px;
      background-color: $bg-active-color;
      line-height: 1.5em;
      .filter-clients-found-title {
        color: $title-text-color;
        font-weight: bold;
      }
    }
  }
}
