// Uses ActiveDBsComponent styles
.databases-container {
  .database-title-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
  .database-title {
    display: flex;
    gap: 6px;
    &::before {
      display: block;
      content: url(/assets/images/dorpdown_arrow.svg);
      height: 20px;
      width: 20px;
      transform: rotate(0deg);
    }
    &.hiden {
      &::before {
        transform: rotate(-90deg);
      }
    }
  }
}
