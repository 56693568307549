body.crm-view {
  .modal fade show {
    background-color: rgba(23, 23, 23, 0.9);
  }
  .popup-container {
    button.open-modal {
      background-color: transparent;
      border: 0;
      padding: 0;
    }
    .modal-dialog {
      margin: 12px;
      max-width: 900px;
      .modal-content {
        padding: 24px;
        background-color: $card-bg-color;
        border-radius: 16px;
        border: 1px solid $border-color;
        box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.4);
        .modal-footer {
          display: flex;
          gap: 24px;
          margin-top: 24px;
          padding: 24px 0 0;
          border-top: 1px solid $input-border-color;
          & * {
            margin: 0;
          }
        }
      }
      @media (min-width: $mobile_h) {
        margin: 100px auto 0;
      }
    }
  }
  .open-modal {
    outline: unset;
  }
  .modal-title {
    margin: 0 0 24px;
    color: $title-text-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
    word-break: break-all;
  }
}
