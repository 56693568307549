.chat-settings-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .chat-settings-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .button-container {
      display: flex;
      gap: 24px;
    }
  }
  .instruction-container {
    padding: 0;
    margin-top: 24px;
    .list-group-numbered {
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-size: 16px;
    }
    .instruction-link {
      color: $link-color;
    }
    .image-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      background-color: $script-bg-color;
    }
  }
  @media (min-width: $tablet_v) {
    .chat-settings-form {
      flex-direction: row;
    }
  }
}
