.profile-card {
  .card-body.card-img-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background-image: url("../../assets/images/panel_bg.png");
  }
  img {
    width: 170px;
  }
}
