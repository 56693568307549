.statistic-dashboard-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  font-size: 12px;
  th,
  td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }

  .form-control {
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    font-size: 12px;
  }

  .day-total {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
