.statistic-tablet-container {
  .react-bootstrap-table {
    overflow-x: auto;
  }
  .react-bootstrap-table table {
    width: auto;
  }
  .react-bs-table {
    overflow-x: auto;
  }
  .react-bs-container-header,
  .react-bs-container-body {
    width: auto;
  }

  @media (min-width: $tablet_h) {
    .react-bs-container-header,
    .react-bs-container-body {
      width: 100%;
    }
    .react-bootstrap-table table {
      width: 100%;
    }
    table {
      th,
      td {
        word-break: break-word;
      }
    }
    .form-group.input-group-container {
      width: 300px;
    }
  }
}
