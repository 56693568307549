.db-actions-container {
  .card {
    flex-direction: column;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  @media (min-width: $tablet_v) {
    .card {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .button-container {
      flex-direction: row;
    }
  }
}
