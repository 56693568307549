.db-managers-statistics-container {
  .data-base-name {
    span {
      margin-left: 4px;
      color: $success-color;
      font-weight: 600;
    }
  }
  .download-statuses-select {
    height: 300px;
  }

  .react-bootstrap-table {
    overflow-x: auto;
    table {
      width: auto;
    }
  }
  @media (min-width: $tablet_v) {
    .react-bootstrap-table {
      overflow-x: unset;
      table {
        width: 100%;
      }
    }
  }
}

.imported-data {
  th,
  td {
    min-width: 100px;
  }
}
