.small-notion {
  display: flex;
  align-items: flex-start;
  padding: 4px 12px;
  width: fit-content;
  border-radius: 1000px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5em;
  color: $title-text-color;
  &.transparent {
    border: 1px solid $input-border-color;
    color: $nav-text-color;
  }
}
