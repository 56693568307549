footer {
  margin-bottom: 1.5rem;
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
      display: block;
    }
    span {
      font-size: 12px;
      line-height: 1.5em;
    }
    a {
      font-size: 14px;
      line-height: 1.5em;
      font-weight: 700;
      color: $title-text-color;
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      display: inline-block;
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
}
