.ip-control {
  .header-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }
  @media (min-width: $mobile_h) {
    .header-info-container {
      flex-direction: row;
      align-items: center;
    }
  }
}
