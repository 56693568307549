.search-resault-container {
  .react-bootstrap-table {
    overflow-x: auto;
  }
  .react-bootstrap-table table {
    width: auto;
  }
  @media (min-width: $tablet_h) {
    .react-bootstrap-table table {
      width: 100%;
    }
  }
}
