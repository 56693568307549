/* libs */
@import "material-icons/iconfont/filled.css";
@import "material-icons/iconfont/outlined.css";

@import "rsuite/dist/rsuite.min.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "react-select-2/dist/css/react-select-2";
@import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

/* Variables */
@import "./variables.scss";

/* Components imports*/
@import "./App.scss";
@import "./../components/HeaderComponent/HeaderComponent.scss";
@import "./../components/SearchBarComponent/SearchBarComponent.scss";
@import "./../components/PaymentStatusComponent/PaymentStatusComponent.scss";
@import "./../components/ImportDBInstructionsComponent/ImportDBInstructionsComponent.scss";
@import "./../components/ImportDBFormComponent/ImportDBFormComponent.scss";
@import "./../components/AddTestComponent/AddTestComponent.scss";
@import "./../components/TestQuestionsFormComponent/TestQuestionsFormComponent.scss";
@import "./../components/FindClientFormComponent/FindClientFormComponent.scss";
@import "./../components/SortingTableComponent/SortingTableComponent.scss";
@import "./../components/ClientsFiltersComponent/ClientsFiltersComponent.scss";
@import "./../components/ActiveDBsComponent/ActiveDBsComponent.scss";
@import "./../components/CalledDBsComponent/CalledDBsComponent.scss";
@import "./../components/PreinstallComponent/PreinstallComponent.scss";
@import "./../components/ManageStatusesComponent/ManageStatusesComponent.scss";
@import "./../components/SearchResultComponent/SearchResultComponent.scss";
@import "./../components/ModalComponent/ModalComponent.scss";
@import "./../components/DailyStatisticComponent/DailyStatisticComponent.scss";
@import "./../components/SmallNotionComponent/SmallNotionComponent.scss";
@import "./../components/WorkingContactsComponent/WorkingContactsComponent.scss";
@import "./../components/ActiveRecallsComponent/ActiveRecallsComponent.scss";
@import "./../components/FooterComponent/FooterComponent.scss";
@import "./../components/StatisticComponent/StatisticComponent.scss";
@import "./../components/StatisticTableComponent/StatisticTableComponent.scss";
@import "./../components/DBCallsChartComponent/DBCallsChartComponent.scss";
@import "./../components/DBActionsComponent/DBActionsComponent.scss";
@import "./../components/DBContactsTableComponent/DBContactsTableComponent.scss";
@import "./../components/UserStatusSelectorComponent/UserStatusSelectorComponent.scss";
@import "./../components/DBManagersStatisticsTableComponent/DBContactsTableComponent.scss";
@import "./../components/ClientsCallsTableComponent/ClientsCallsTableComponent.scss";
@import "./../components/EditManagerComponent/EditManagerComponent.scss";
@import "./../components/ProfilePreviewComponent/ProfilePreviewComponent.scss";
@import "./../components/ChangeLoginComponent/ChangeLoginComponent.scss";
@import "./../components/ChangePasswordComponent/ChangePasswordComponent.scss";
@import "./../components/CustomPagination/CustomPagination.scss";
@import "./../components/LoginFormComponent/LoginFormComponent.scss";
@import "./../components/AlertComponent/AlertComponent.scss";
@import "./../components/ManagerWorkspaceComponent/ManagerWorkspaceComponent.scss";
@import "./../components/DBDataConfigurationComponent/DBDataConfigurationComponent.scss";
@import "./../components/AddAndEditScriptComponent/AddAndEditScriptComponent.scss";
@import "./../components/EditTestComponent/EditTestComponent.scss";
@import "./../components/TestResultsComponent/TestResultsComponent.scss";
@import "./../components/TestStatisticComponent/TestStatisticComponent.scss";
@import "./../components/ManagerCallsComponent/ManagerCallsComponent.scss";
@import "./../components/WeeklyIncomesComponent/WeeklyIncomesComponent.scss";
@import "./../components/DocumentTemplates/index.scss";
@import "./../components/OverlayAndSpinner/OverlayAndSpinner.scss";
@import "./../components/NotificationComponent/NotificationComponent.scss";
@import "./../components/NavComponent/NavComponent.scss";
@import "./../components/ChartComponent/ChartComponent.scss";
@import "./../components/ButtonComponent/ButtonComponent.scss";
@import "./../components/InputComponent/InputComponent.scss";
@import "./../components/ClientMainInfoComponent/ClientMainInfoComponent.scss";
@import "./../components/BankLinksComponent/BankLinksComponent.scss";
@import "./../components/ManagerSidebarComponent/ManagerSidebarComponent.scss";
@import "./../components/ManagerScriptsComponent/ManagerScriptsComponent.scss";
@import "./../components/CreateClientsFiltersComponent/CreateClientsFiltersComponent.scss";
@import "./../components/CheckboxComponent/CheckboxComponent.scss";
@import "./../components/SelectCheckboxCustomComponent/SelectCheckboxCustomComponent.scss";
@import "./../components/SelectCustomComponent/SelectCustomComponent.scss";
@import "./../components/ChatSettingsComponent/ChatSettingsComponent.scss";
@import "./../components/IPControlComponent/IPControlComponent.scss";
@import "./../components/CopyToClipboardButton/CopyToClipboardButton.scss";
@import "./../components/StatisticDashboardTable/StatisticDashboardTable.scss";

/* Reporting */
@import "./../components/ReportingHeaderComponent/ReportingHeaderComponent.scss";
@import "./../components/ReportingIncomeExpenseComponent/ReportingIncomeExpenseComponent.scss";
@import "./../components/ReportingDatePicker/ReportingDatePicker.scss";
@import "./../components/ThemeSwitcher/ThemeSwitcher.scss";
/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: Arial, Apple-System, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, STXihei, sans-serif;
  line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

input[type="date"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  min-height: 1.2em;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
