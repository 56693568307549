.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  & > * {
    width: calc((100% - 24px) / 2) !important;
  }
  @media (min-width: $tablet_h) {
    flex-wrap: nowrap;
    & > * {
      width: 100% !important;
    }
  }
}
