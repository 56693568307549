.notification-container {
  .notification-button-wrapper {
    position: relative;
  }
  .icon {
    position: relative;
    color: $link-color;
    font-size: 20px;
  }
  .notification-indicator {
    position: absolute;
    top: 2px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: $danger-color;
  }
  .notification-button {
    position: relative;
    &:not([disabled]) {
      pointer-events: painted;
    }
    &.disabled {
      .notification-indicator {
        display: none;
      }
    }
  }
  .notifications-container {
    position: absolute;
    top: calc(100% + 2px);
    z-index: 10;
    transform: translate(-80%, 0);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    border: 1px solid $input-border-color;
    border-radius: 5px;
    background: $card-bg-color;
    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
    transition: visibility 0s, opacity 0.25s linear;
    &.hiden {
      visibility: hidden;
      opacity: 0;
    }
    &.shown {
      visibility: visible;
      opacity: 1;
    }
    .notification {
      width: 350px;
    }
  }
  .notification {
    display: flex;
    justify-content: space-between;
    padding: 6px 12px;
    gap: 6px;
    &:hover,
    &:focus {
      background: $border-color;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $input-border-color;
    }
  }
  .close-button {
    padding: 0;
    background-color: transparent;
    height: fit-content;
  }

  .notifications-modal {
    padding: 10px;
    background-color: $card-bg-color;
    max-height: 90dvh;
    overflow-y: auto;
    .notification {
      flex-direction: column;
      gap: 8px;
    }
  }
  .agree-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
