.import-db-instructions {
  .import-db-instructions-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    list-style-type: decimal;
    padding-left: 1rem;
    li {
      color: title-text-color;
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}
