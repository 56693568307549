.payment-status {
  font-size: 0.6rem;
  font-weight: 600;
  background-color: $disabled-color;
  border-radius: 3px;
  padding: 4px;
  height: fit-content;
  &.danger {
    background-color: $danger-color;
  }
  &.warning {
    background-color: $warning-color;
  }
  &.success {
    background-color: $success-color;
  }
}
