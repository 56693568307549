.working-contacts-container {
  .total-in-work-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .row-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .cell-title {
    font-size: 14px;
    font-weight: bold;
    color: $title-text-color;
  }
  .cell-data {
    color: $nav-text-color;
  }
  thead {
    display: none;
  }
  .cell-container {
    display: flex;
    gap: 6px;
    & > div {
      width: calc(50% - 3px);
    }
  }
  @media (min-width: $tablet_v) {
    thead {
      display: table-header-group;
    }
  }
}
