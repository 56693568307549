@mixin alert_icon($image) {
  display: block;
  width: 32px;
  height: 32px;
  background-color: #fff;
  padding: 6px;
  content: #{$image};
  border-radius: 50%;
}

.alert {
  position: fixed;
  z-index: 10;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px;
  cursor: pointer;
  max-width: 336px;
  border: none;
  color: $title-text-color;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: normal;
  white-space: pre-line;
  &-success {
    background-color: $success-color;
    &::before {
      @include alert_icon(url(/assets/images/success-icon.svg));
    }
  }
  &-danger {
    background-color: $danger-color;
    &::before {
      @include alert_icon(url(/assets/images/danger-icon.svg));
    }
  }
}
