.preinstall-container {
  .settings-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 24px;
  }
  .settings-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: $title-text-color;
  }
  .settings-controls {
    display: flex;
    gap: 24px;
  }
  @media (min-width: $tablet_v) {
    .settings-heading {
      flex-direction: row;
      align-items: center;
    }
  }
}
