.custom-dropdown {
  position: relative;
  cursor: default;
  width: 100%;
  height: fit-content;
  .dropdown-wrapper {
    &:hover,
    &:focus,
    &.active {
      & ~ .label-name {
        color: $hover-input-border-color;
      }
    }
  }
  .selected-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    color: $title-text-color;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5em;
    white-space: nowrap;
    &::after {
      display: block;
      content: url(/assets/images/dorpdown_arrow.svg);
      height: 20px;
      width: 20px;
      transform: rotate(0deg);
    }

    &.active {
      background-color: $bg-active-color;
      &::after {
        transform: rotate(180deg);
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.65;
    }
  }

  .options {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
    li {
      display: flex;
      align-items: center;
      padding: 7.5px 12px;
      background-color: $card-bg-color;
      cursor: default;
      transition: background-color 0.2s;
      color: $nav-text-color;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5em;
      &:hover {
        background-color: $bg-active-color;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $input-border-color;
      }
    }
  }
  .label-name {
    width: fit-content;
    color: $nav-text-color;
    font-size: 12px;
    line-height: 1em;
    transition: color 0.3s;
    &:hover,
    &:focus,
    &:active {
      color: $hover-input-border-color;
    }
  }
  &.label-is {
    display: flex;
    flex-direction: column-reverse;
    gap: 6px;
  }
}
