.db-by-client-statistic-container {
  .chart-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .chat-title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    h6 {
      width: fit-content;
    }
  }
  @media (min-width: $tablet_v) {
    .card {
      flex-direction: row;
    }
  }
}
