.db-data-configuration-container {
  max-height: 70vh;

  .config-data-heading {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .config-data-buttons {
    display: flex;
    justify-content: space-between;
  }
  .imported-data-container {
    overflow: auto;
  }

  table.imported-data {
    tbody {
      tr {
        border-color: $input-border-color;
        border-style: solid;
        border-width: 1px;
        border-left: none;
        border-right: none;
      }
    }
    thead {
      tr {
        border: unset !important;
      }
      td {
        padding-left: 6px;
        padding-right: 6px;
        &:last-child {
          padding: 12px 0 12px 12px;
        }
        &:first-child {
          padding: 12px 12px 12px 0;
        }
      }
    }
    th,
    td {
      color: $nav-text-color;
      font-weight: 400;
      padding: 12px;
      font-size: 12px;
      line-height: 1.5em;
    }
  }

  @media (min-width: $mobile_h) {
    .config-data-heading {
      flex-direction: row;
      justify-content: space-between;
    }
    .config-data-buttons {
      gap: 24px;
    }
  }
}
