$page-bg-color: #171717;
$card-bg-color: #252525;
$scroll-bg-color: #424242;
$script-bg-color: #fff;
$title-text-color: #fff;
$text-color: #e7e7e0;
$dark-text-color: #9298a8;
$accent-color: #b15e83;
$semi-accent-color: #c29ffa;
$loader-overlay-color: rgba(52, 63, 83, 0.8);
$border-color: #323232;
$input-border-color: #3f3f3f;
$placeholder-text-color: #7a7a7a;
$hover-input-border-color: #7a7a7a;
$hover-label-color: #d2d2d2;

$grey-color: #636567;

$disabled-color: var(--bs-secondary);
$success-color: var(--bs-success);
$warning-color: var(--bs-warning);
$danger-color: var(--bs-danger);
$info-color: var(--bs-info);
$bg-active-color: #323232;

$notification-icon-color: rgb(255, 208, 0);
$shadow-color: gray;

$table-head-text-color: #fff;
$table-body-text-color: #d2d2d2;
$nav-text-color: #d2d2d2;
$link-color: #0d6efd;
/* Dark theme */
$dark--page-bg-color: #171717;
$dark--card-bg-color: #252525;
$dark--card-secondary-bg-color: #646363;
$dark--text-color: #e7e7e0;
$dark--border-color: #3f3f3f;

@keyframes blinker {
  0% {
    color: $notification-icon-color;
    // text-shadow: 0 0.4em 1em $shadow-color, 0 0 0.5em $shadow-color;
  }
  50% {
    color: $shadow-color;
    // text-shadow: none;
  }
  100% {
    color: $notification-icon-color;
    // text-shadow: 0 0.4em 1em $shadow-color, 0 0 0.5em $shadow-color;
  }
}

@mixin blinker() {
  animation-name: blinker;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1.5s;
}
