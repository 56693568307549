header {
  .header-top-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .manager-header-top-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
  }
  .manager-profile-notification-container {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
  .profile-wrapper {
    font-size: 20px;
  }
  .app-logo {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    font-size: 16px;
    line-height: 1.5em;
  }
  .app-user {
    font-weight: bold;
  }
  .profile-notification-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .profile-container {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: $tablet_v) {
    .header-top-container {
      flex-direction: row;
      justify-content: space-between;
    }
    .manager-header-top-container {
      flex-direction: row;
      justify-content: space-between;
    }
    .profile-notification-container {
      flex-direction: row-reverse;
    }
    .manager-profile-notification-container {
      flex-direction: row-reverse;
    }
    .profile-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: unset;
    }
  }
}
