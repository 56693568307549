.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  @media (min-width: $tablet_v) {
    .inputs-container {
      flex-direction: row;
      align-items: end;
    }
  }
}
