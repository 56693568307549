@import "./colors.scss";

/*--- MATERIAL ICONS ---*/
$material-icons-font-size: 20px;

$desktop: 1400px;
$tablet_h: 1200px;
$tablet_v: 992px;
$mobile_h: 576px;
$mobile_v: 360px;

@mixin blackout_effect($border_radius) {
  position: relative;
  &::before {
    border-radius: #{$border_radius};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s;
  }
}

@mixin hover_focus_blackout_effect($border_radius) {
  @include blackout_effect($border_radius);
  &:hover::before,
  &:focus::before {
    opacity: 1;
  }
}

@mixin status_general_style() {
  border-radius: 1000px;
  color: $title-text-color;
  font-size: 12px;
  font-weight: 700;
  line-height: 1em;
  padding: 12px 24px;
}

@mixin base-style-dark {
  background-color: $dark--card-bg-color;
  color: $dark--text-color;
}

// custom classes with breakpoints
.overflow-x-auto {
  overflow-x: auto !important;
}
@media (min-width: $mobile_h) {
  .overflow-x-mh-visible {
    overflow-x: visible !important;
  }
}
