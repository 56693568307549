.recall-client-container {
  .head-recall-container {
    display: flex;
    gap: 24px;
  }
  .total-recalls-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .recall-client-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .contact-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .material-icons {
      font-size: 16px;
      margin-right: 6px;
    }
    > *:not(.clinet-name) {
      font-size: 12px;
      color: $nav-text-color;
    }
  }
}
