.manager-scripts-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .scripts-block.card {
    height: fit-content;
    row-gap: 12px;
  }

  .script-title {
    color: $title-text-color;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
  }
  .script-canvas {
    background-color: #fff;
    color: black;
    border-radius: 8px;
    overflow-y: auto;
    max-height: 70vh;
  }
  .popup-container {
    &:not(:last-child) {
      border-bottom: 1px solid $input-border-color;
    }
  }
  .scripts-container {
    max-height: 66vh;
    overflow: auto;
  }
  .script-item {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    color: #0d6efd;
    font-size: 16px;
    font-style: normal;
    line-height: 1.5em;
  }
  .script-icon {
    font-size: 20px;
  }
  .empty-message {
    font-weight: 700;
  }
  .open-modal {
    width: 100%;
  }
}
