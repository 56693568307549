.import-db-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .check-db-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .file-name-pseudolabel {
    margin-bottom: 6px;
    color: $placeholder-text-color;
    font-size: 12px;
    line-height: 1.5em;
  }
  .file-selector-wrapper {
    display: flex;
    gap: 24px;
    .file-name {
      min-height: 100%;
      width: 100%;
      padding: 6px 12px;
      align-items: center;

      border-radius: 4px;
      border: 1px solid $input-border-color;
    }
    .button-name {
      white-space: nowrap;
    }
    input {
      display: none;
    }
  }
  .submit-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
