.bank-link-container {
  .bank-links-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  span {
    font-size: 16px;
    line-height: 1.5em;
  }
  .bank-link {
    font-size: 16px;
    line-height: 1.5em;
    color: $link-color;
  }
}
