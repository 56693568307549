.input-group-container {
  position: relative;
  line-height: 1em;
  display: flex;
  gap: 6px;
  flex-direction: column-reverse;
  height: fit-content;
  input,
  textarea {
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      border-color: $hover-input-border-color;
      & ~ label {
        color: $hover-input-border-color;
      }
    }
  }
  label {
    width: fit-content;
    color: $nav-text-color;
    font-size: 12px;
    line-height: 1em;
    transition: color 0.3s;
    &:hover,
    &:focus,
    &:active {
      color: $hover-input-border-color;
    }
  }
  .material-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    font-size: 20px;
    color: $placeholder-text-color;
    pointer-events: none;
    transition: opacity 0.3s;
    opacity: 1;
    &.clear {
      pointer-events: auto;
      cursor: default;
    }
  }
  &.label-is {
    .material-icons {
      top: calc(50% + 9px);
    }
  }
  .form-control {
    padding-right: 2rem !important;
    height: auto;
    &[disabled] {
      cursor: not-allowed;
      & ~ .clear {
        display: none;
      }
    }
  }

  /* NOTE!: Customization options for scrollbar styles are very limited. This is the maximum I could do.*/
  // textarea {
  // &::-webkit-scrollbar {
  //   width: 17px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #686868;
  //   background-clip: padding-box;
  //   border: 2px solid $scroll-bg-color;
  // }

  // &::-webkit-scrollbar-track {
  //   background: $scroll-bg-color;
  // }
  // /* Buttons */
  // &::-webkit-scrollbar-button:single-button {
  //   background-color: #bbbbbb;
  //   display: block;
  //   border-style: solid;
  //   height: 17px;
  //   width: 17px;
  // }

  /* Up */
  // &::-webkit-scrollbar-button:single-button:vertical:decrement {
  //   padding: 2px;
  //   border-width: 0 8px 8px 8px;
  //   border-color: #424242 transparent #424242 transparent;
  // }

  // &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  //   border-color: transparent transparent #777777 transparent;
  // }
  // /* Down */
  // &::-webkit-scrollbar-button:single-button:vertical:increment {
  //   border-width: 8px 8px 0 8px;
  //   border-color: #555555 transparent transparent transparent;
  // }

  // &::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  //   border-color: #777777 transparent transparent transparent;
  // }
  // }
}
