.manager-work-space-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .client-main-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .user-information-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .without-sidebar-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  @media (min-width: $tablet_v) {
    .client-main-container {
      flex-direction: row;
    }
  }
}
