.search-bar {
  .search-input-wrapper {
    position: relative;
    .material-icons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      font-size: 20px;
      color: $placeholder-text-color;
      pointer-events: none;
      transition: opacity 0.3s;
      opacity: 1;
      &.clear {
        pointer-events: auto;
        cursor: default;
      }
    }
  }
  .form-control {
    padding-right: 2rem !important;
  }
}
