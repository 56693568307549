.theme-switcher {
  .form-check-input {
    width: 3rem;
    height: 1.5rem;
    cursor: pointer;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($shadow-color, 0.2);
    }
  }
  .form-check-input:checked {
    background-image: url(../../assets/images/moon.webp);
    background-color: $shadow-color;
    border-color: $shadow-color;
  }
  .form-check-input:not(:checked) {
    background-image: url(../../assets/images/sun.webp);
    background-color: $text-color;
    border-color: $text-color;
  }
}
