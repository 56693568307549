.test-statistic-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .header-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .test-statistic-title {
    color: var(--FFFFFF, #fff);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
  }
  @media (min-width: $mobile-h) {
    .header-container {
      flex-direction: row;
    }
    .test-statistic-title,
    .form-group {
      flex: 1;
    }
  }
}
