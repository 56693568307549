.create-clients-filter-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .selector-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .label-name {
      color: $title-text-color;
      font-size: 18px;
      font-weight: 700;
    }
    .selector-note {
      color: $nav-text-color;
      font-size: 16px;
      line-height: 1.5em;
      margin-top: -12px;
    }
  }
  .selector-extra-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    overflow-x: clip;
  }
  .statuses-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .status-item {
      @include status_general_style;
      cursor: pointer;
    }
  }
}
