.statistic-component-container {
  .hedear-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    row-gap: 24px;
    .positioned {
      position: absolute;
      top: 50%;
      right: 0;
      translate: translateY(-50%);
    }
  }
  .total-calls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .total-calls-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .date-picker-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: bold;
  }

  @media (min-width: $tablet_v) {
    .hedear-container {
      flex-direction: row;
      align-items: center;
    }
    .total-calls-container {
      width: fit-content;
    }
  }
  @media (max-width: calc(#{$tablet_v} - 1px)) and (min-width: $mobile_h) {
    .total-calls-wrapper {
      flex-direction: row;
    }
  }
}
