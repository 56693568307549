.custom-checkbox-container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: fit-content;
  .custom-checkbox-wrapper {
    margin: 0;
    padding: 0;
    .form-check-input[type="checkbox"] {
      width: 22px;
      height: 22px;
      margin: 0;
      background-color: unset;
      padding: 0;
      border: none;
      background-image: url("../../assets/images/checkbox_default .svg");
      &:checked {
        background-image: url("../../assets/images/checkbox_checked.svg");
      }
      &.indeterminate {
        background-image: url("../../assets/images/checkbox_indeterminate.svg");
      }
      &:focus {
        box-shadow: unset;
      }
    }
  }
}
