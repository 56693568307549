.databases-container {
  .databases-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    .databases-buttons {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
  .databases-title-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    .search-bar {
      width: 170px;
    }
  }
  .react-bootstrap-table {
    overflow-x: auto;
    table {
      width: auto;
    }
  }
  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(60, 60, 60, 0.7);
  }
  .loader-spinner-container {
    position: sticky;
  }

  @media (min-width: $tablet_v) {
    .databases-title-container {
      flex-direction: row;
      align-items: center;
    }
    .loader-spinner-container {
      position: absolute;
    }
  }
}
