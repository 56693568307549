.pagination-block {
  margin-top: 24px;
  color: $nav-text-color !important;
  .dropdown {
    .dropdown-toggle {
      position: relative;
      padding-right: 34px;
      line-height: 1.5em;
      background-color: $card-bg-color;
      border-radius: 4px;
      border: 1px solid $input-border-color;
      font-size: 14px;
      font-weight: 400;
      color: $nav-text-color !important;

      &::after {
        top: 8px;
        right: 8px;
        position: absolute;
        content: url(/assets/images/three_dots.svg);
        border: none;
      }
      &:focus {
        border-radius: 4px;
        border: 1px solid $border-color;
        box-shadow: none;
      }
    }
    &.show {
      .dropdown-toggle {
        border-radius: 4px;
        border: 1px solid $border-color;
        background: $input-border-color;
      }
    }
    .dropdown-menu {
      min-width: unset;
      width: 100%;
      padding: 0;
      border-radius: 4px;
      border: 1px solid $border-color;
    }
    .dropdown-item {
      display: flex;
      height: 36px;
      padding: 6px 12px;
      align-items: center;
      line-height: 1.5em;
      background-color: $card-bg-color;
      font-size: 14px;
      font-weight: 400;
      color: $nav-text-color !important;
      &:not(:first-child) {
        border-top: 1px solid $border-color;
      }
      &:hover,
      &.active {
        background-color: $bg-active-color;
        text-decoration: none;
      }
    }
  }
  .pagination {
    border-radius: 4px;
    border: 1px solid $input-border-color;
  }
  .page-item:not(:first-child) {
    border-left: 1px solid $input-border-color;
  }
  .page-link {
    line-height: 1.5em;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: $nav-text-color !important;

    &:hover,
    &:focus {
      background-color: $bg-active-color;
      text-decoration: none;
    }
  }

  .page-item.active {
    .page-link {
      background-color: $bg-active-color;
      text-decoration: none;
    }
  }
}
