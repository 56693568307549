.nav-component-container {
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  flex: auto;
  .dropdown-toggle {
    padding: 5px 17px 5px 11px;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    color: $title-text-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5em;
    cursor: pointer;
    &.show {
      background-color: $bg-active-color;
    }
    &::after {
      margin-left: 11px;
    }
    &:hover {
      background-color: $bg-active-color;
    }
  }
  .nav-item-wrapper {
    width: fit-content;
  }
  .nav-name-wrapper {
    min-height: 24px;
  }
  .nav-icon {
    margin-right: 6px;
    font-size: 20px;
    color: $link-color;
    &.profile-icon {
      color: $title-text-color;
    }
  }
  .dropdown-menu {
    width: max-content;
    min-width: unset;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    background-color: $card-bg-color;
    .item-nav-link:first-child {
      .dropdown-item {
        border-radius: 3px 3px 0 0;
      }
    }
    .item-nav-link:last-child {
      .dropdown-item {
        border-radius: 0 0 3px 3px;
      }
    }
    .item-nav-link:not(:last-child) {
      .dropdown-item {
        border-bottom: 1px solid $input-border-color;
      }
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 11px;
    min-height: 36px;
    color: $nav-text-color;
    white-space: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
    &.title-nav {
      color: $title-text-color;
      font-weight: bold;
      border-radius: 4px;
      border: 1px solid $input-border-color;
    }
    &.active,
    &:hover,
    &:focus {
      background-color: $bg-active-color;
      text-decoration: none;
    }
  }
  .item-nav-link {
    list-style-type: none;
  }
  .nav-name-wrapper,
  .item-nav-link {
    flex-grow: 1;
    overflow: hidden;
  }
  .label,
  .nav-item-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media (min-width: $tablet_v) {
    flex-direction: row;
  }
}
