.login-container {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0 20px;
  background: center / cover no-repeat
    url("/assets/images/login_backlog_360.png");

  @media (min-width: $mobile_h) {
    background: center / cover no-repeat
      url("/assets/images/login_backlog_576.png");
  }

  @media (min-width: $tablet_v) {
    background: center / cover no-repeat
      url("/assets/images/login_backlog_992.png");
  }
  @media (min-width: $tablet_h) {
    background: center / cover no-repeat
      url("/assets/images/login_backlog_1200.png");
  }
  @media (min-width: $desktop) {
    background: center / cover no-repeat
      url("/assets/images/login_backlog_1400.png");
  }
}
.form-wrapper {
  margin: 0 auto;
}
.login-form {
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(25px);
  width: 100%;
  max-width: 480px;
  margin: auto;
  .logo-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    text-align: center;
  }
  .logo-title {
    color: $nav-text-color;
    font-family: Arial;
    font-size: 12px;
    line-height: 1.5em;
  }
  .login-icon {
    font-size: 20px;
  }
  .login-button {
    height: 36px;
    font-size: 14px;
  }
  @media (min-width: $mobile_h) {
    padding: 48px;
    width: 480px;
  }
}
