.daily-statistic-container {
  .total-calls-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (min-width: $tablet_v) {
      flex-direction: row;
    }
  }
}
