.manage-statuses-container {
  .settings-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 24px;
  }
  .settings-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .settings-controls {
    display: flex;
    gap: 24px;
  }
  .bordered {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .status-main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 100%;
      .form-control:not(:focus) {
        color: $placeholder-text-color;
        & ~ .clear {
          opacity: 0;
        }
      }
    }
    .arrow-icon {
      display: none;
    }
    .status-old-name-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      width: 100%;
    }
    .status-old-name {
      @include status_general_style;
      position: relative;
    }
  }
  .status-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
  .desktop-delete-button-wrapper,
  .mobile-delete-button-wrapper {
    min-width: 36px;
  }
  .desktop-delete-button-wrapper {
    display: none;
  }

  @media (min-width: $tablet_v) {
    .settings-heading {
      flex-direction: row;
      align-items: center;
    }
    .bordered {
      gap: 12px;
      .status-main-container {
        flex-direction: row;
        .input-group-container {
          width: 240px;
        }
      }
      .arrow-icon {
        display: block;
        color: $placeholder-text-color;
        font-size: $material-icons-font-size;
      }

      .status-container {
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
      .status-old-name-wrapper {
        width: auto;
      }
      .desktop-delete-button-wrapper {
        display: initial;
      }
      .mobile-delete-button-wrapper {
        display: none;
      }
    }
  }
}
