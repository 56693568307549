.questions-container {
  color: $title-text-color;
  font-size: 16px;
  line-height: 1.5em;
  .question {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
  .entity-identifier {
    width: 24px;
    font-weight: 700;
  }
  .questions-answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-left: 16px;
    width: 100%;
    &.wide-answer {
      gap: unset;
    }
  }
  .left-answer-container {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }
  .answer-options-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 3;
    gap: 24px;
  }
  .question-answer-container {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
  }
  .question-answer {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    width: 100%;
  }
  .answer-checkboxes-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    text-wrap: nowrap;
  }
  .question-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 100%;
    .custom-checkbox-container {
      justify-content: center;
    }
  }
  .right-answer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 3;
  }
  .right-answer-actions-container {
    display: flex;
    gap: 24px;
  }
  @media (min-width: $tablet_v) {
    .questions-answers {
      gap: 48px;
      flex-direction: row;
      padding-left: 48px;
      &.wide-answer {
        gap: 48px;
      }
    }
    .left-answer-container {
      display: flex;
      align-items: flex-start;
      gap: 48px;
      flex: 4;
      width: unset;
    }
    .question-answer-container {
      gap: 48px;
    }
  }
  @media (min-width: $mobile-h) {
    .answer-checkboxes-container {
      flex: 1;
    }
  }
}
