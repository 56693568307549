.add-and-edit-container {
  .action-container {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
  }
  .button-subcontainer {
    display: flex;
    gap: 24px;
  }
  .text-variables {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #3f3f3f;
    h5 {
      color: $title-text-color;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5em;
    }
    p {
      color: $table-body-text-color;
      font-size: 14px;
      line-height: 2em;
    }
  }
  .rdw-editor-wrapper {
    border-radius: 8px;
    background-color: white;
    color: black;
    height: 100%;
    .rdw-editor-main {
      height: auto;
      max-height: 60vh;

      min-height: 80px;
      padding-inline: 1rem;
    }
  }
  @media (min-width: $tablet_v) {
    .action-container {
      flex-direction: row;
    }
    .buttons-container {
      justify-content: end;
    }
    .editor-container {
      //   max-height: 60vh;
      & > * {
        height: 100%;
      }
    }

    .text-variables {
      height: 100%;
    }
  }
}
