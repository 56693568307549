.test-results-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: unset;
    gap: 12px;
  }
  .test-results-modal-title {
    flex: 1.5;
    margin: 0;
    color: $title-text-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
  }
  .selector-wrapper {
    flex: 1;
  }
  .question-block {
    display: flex;
    width: 100%;
    padding: 24px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
  }
  .question-text {
    color: $title-text-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
  }
  .answers-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  .answer-variant-text {
    padding: 0px 12px;

    font-size: 16px;
    line-height: 1.5em;
  }
  @media (min-width: $mobile-h) {
    .header-container {
      flex-direction: row;
      align-items: center;
      align-self: stretch;
    }
  }
}
