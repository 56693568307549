.manager-calls-container {
  .react-bootstrap-table table {
    width: auto;
  }

  @media (min-width: $mobile_h) {
    .react-bootstrap-table table {
      width: 100%;
    }
  }
}
