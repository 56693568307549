.add-test-container {
  .header-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .action-container {
    display: flex;
    justify-content: end;
    gap: 12px;
  }
  @media (min-width: $mobile-h) {
    .header-container {
      flex-direction: row;
      gap: 24px;
    }
    .action-container {
      gap: 24px;
    }
  }
}
