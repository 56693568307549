.select-container {
  position: relative;
  width: 100%;
  height: 100%;
  .dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .dropdown {
    display: flex;
    gap: 12px;
    height: 36px;
    width: 100%;
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    .collection-container {
      width: 100%;
      overflow-x: hidden;
      .collection-wrapper {
        display: flex;
        gap: 6px;
        width: fit-content;
      }
      .collection-item {
        padding: 1.5px 12px;
        border-radius: 2px;
        background-color: $link-color;
        color: $title-text-color;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
      }
    }
    .hiden-collection-counter {
      min-width: 16px;
      color: $title-text-color;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5em;
    }
    &::after {
      content: url(/assets/images/dorpdown_arrow.svg);
      height: 20px;
      transform: rotate(180deg);
    }
    &.hiden {
      &::after {
        transform: rotate(0deg);
      }
    }
  }
  .item-list {
    border-radius: 4px;
    border: 1px solid $input-border-color;
    background-color: $card-bg-color;
    max-height: 240px;
    overflow-y: auto;
    &:not(.positioned) {
      height: 100%;
    }
    &.positioned {
      position: absolute;
      z-index: 1000;
      top: calc(100% + 2px);
      left: 0;
      width: 100%;
      box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
    }
    &.hiden {
      display: none;
    }

    .group-item {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 12px;
      color: $title-text-color;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5em;
    }

    .item {
      display: flex;
      padding: 8px 12px 8px 24px;
      align-items: center;
      gap: 12px;
      color: $nav-text-color;
      font-size: 16px;
    }
    .group-item,
    .item {
      &:hover {
        background-color: $bg-active-color;
      }
    }
    // It's necessary for the correct checkbox selection
    // by clicking on whole item and group-item
    .checkbox-label {
      pointer-events: none;
    }
  }
}
