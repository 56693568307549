.sorting-table {
  table {
    border-collapse: collapse !important;
    margin-bottom: 0;
    font-size: 14px;

    .form-check {
      width: fit-content;
    }
    .header-check-box {
      border: 3px solid;
    }
    th,
    td {
      padding: 12px 0 12px 12px;
      vertical-align: top;
      border-left: 0;
      border-right: 0;
      color: $text-color;
      &:last-child {
        padding-right: 12px;
      }
    }
    thead {
      tr {
        border-width: 0;
        border-radius: 4px;
        background: $bg-active-color;
      }
      th {
        vertical-align: middle;
        font-weight: 700;
        line-height: 1.5em;
        border: none;
        color: $table-head-text-color;
      }
    }
    tbody {
      tr {
        border: none;
        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }
        &:hover > * {
          color: darken($text-color, 6) !important;
        }
      }
      td {
        color: $table-body-text-color;
      }
    }

    .react-bs-table-no-data {
      text-align: center;
    }
  }
  .react-bs-table-search-form {
    padding-bottom: 1rem;
  }
}
