.sidebar {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .recalls-block.card,
  .transfer-history.card {
    height: fit-content;
    row-gap: 12px;
    padding: 24px 0 !important;
  }
  .sidebar-title {
    color: $title-text-color;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
    padding: 0 24px;
  }
  .recalls-container,
  .transfers-container {
    overflow-y: auto;
    height: unset;
    max-height: 66vh;
  }
  .sidebar-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 24px;
    &:not(:last-child) {
      border-bottom: 1px solid $input-border-color;
    }
  }
  .recall-item {
    cursor: pointer;
    &.active,
    &:hover {
      background-color: $bg-active-color;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .sub-text-container {
    display: flex;
    align-items: center;
    gap: 6px;
    .material-icons {
      font-size: 16px;
    }
  }
  .transfer-user-title {
    font-weight: 700;
  }
  .transfer-user-name {
    color: $link-color;
  }
  .empty-message {
    font-weight: 700;
    padding: 12px 24px;
  }
}
