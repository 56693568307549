.db-table-container {
  .db-actions-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .db-buttons-container {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    .button-name {
      white-space: nowrap;
    }
  }
  .db-buttons-subcontainer {
    display: flex;
    gap: 24px;
  }
  @media (min-width: $tablet_v) {
    .db-actions-container {
      flex-direction: row;
    }

    .db-buttons-container {
      justify-content: unset;
    }
  }
}
