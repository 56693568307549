.user-statuses-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  .status-btn {
    @include status_general_style;
    @include hover_focus_blackout_effect(1000px);
  }
}
.user-action-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .btn-container {
    padding: 6px;
  }
  @media (min-width: $mobile_h) {
    .btn-container {
      padding: 6px 12px;
    }
  }
  @media (min-width: $mobile_v) {
    justify-content: unset;
    gap: 12px;
  }
  .sip {
    img {
      border: 1px solid $title-text-color;
      border-radius: 50%;
    }
  }
}
.timezone-data-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  row-gap: 6px;
  border-radius: 4px;
  background-color: $bg-active-color;
  color: $text-color;
  font-size: 12px;
  line-height: 1.5em;
  .timezone-title {
    color: $placeholder-text-color;
    font-weight: bold;
  }
  .pseudo-arrow {
    position: absolute;
    left: 12px;
    top: -4px;
    border-radius: 1px 0px 0px 0px;
    border-top: 1px solid $nav-text-color;
    border-left: 1px solid $nav-text-color;
    background-color: $bg-active-color;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    @media (min-width: $mobile_h) {
      left: 18px;
    }
    @media (min-width: $tablet_v) {
      left: 55px;
    }
  }
}
.disabled-message {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: $loader-overlay-color;
  padding: 0 24px;
  border-radius: 16px;
}
