.btn-container {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
  border-width: 0 !important;
  text-decoration: none !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 1em;
  &.only-icon {
    padding: 6px;
    input {
      display: none;
    }
  }
  &:hover {
    text-decoration: none;
    box-shadow: none;
    @include hover_focus_blackout_effect(4px);
  }
  &:focus {
    box-shadow: none;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    height: fit-content;
    width: fit-content;
    border-radius: 4px;
    border-width: 0 !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5em;
  }
  &.reverse {
    .btn-wrapper {
      flex-direction: row-reverse;
    }
  }
  .button-icon {
    font-size: 20px;
  }
  .image-wrapper {
    height: 20px;
    width: 20px;
  }
  &.transparent {
    border-radius: 4px;
    outline: 1px solid $input-border-color !important;
    background-color: transparent !important;
    color: $title-text-color;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5em;
  }
  &.btn-highlight {
    color: $title-text-color;
    background-color: $bg-active-color;
  }
  &.btn-xl {
    padding: 8px 12px;
    .btn-wrapper {
      min-height: 20px;
      font-size: 14px;
    }
    &.only-icon {
      padding: 8px;
    }

    .button-name {
      font-size: 14px;
    }
  }
  input[type="submit"] {
    padding: 0;
    background: none;
  }
}
