.client-main-info-container {
  textarea.form-control {
    resize: none;
  }
  .client-additional-info {
    .card {
      height: auto;
    }
    textarea.form-control {
      resize: vertical;
    }
  }
  .buttons-container {
    display: flex;
    gap: 24px;
  }
  .btn-container {
    padding: 8px 12px;
  }
  .button-name {
    font-size: 14px;
  }
}
.empty-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}
