.weekly-incomes {
  label {
    white-space: nowrap;
    height: fit-content;
    margin-right: 0.4rem;
  }
  .table-container {
    overflow: auto;
    overflow-y: scroll;
    max-height: 450px;
    width: 100%;
  }
  .main-row {
    cursor: pointer;
  }
  .additional-row {
    font-size: 12px;
    background-color: #dff5da;
  }
  .weeks-picker-container {
    .dropdown-menu {
      max-height: 40vh;
    }
  }
}
