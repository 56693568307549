.overlay {
  position: fixed;
  top: 0;
  background-color: $loader-overlay-color;
  width: 100%;
  height: 100%;
  z-index: 1070;
}
.loader-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}
